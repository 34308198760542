import httpClient from '../httpClient/httpClient';

const OrganizationsService = {
  createOrganization(name, customerNumber) {
    return httpClient.post('api/create-organization', {
      name,
      customerNumber,
    });
  },
  getOrganizationNames() {
    return httpClient.get('api/get-organization-names');
  },
  getOrganizations() {
    return httpClient.get('api/get-organizations');
  },
  getOrganizationData(organizationID) {
    return httpClient.get('api/get-organization-data', {
      params: {
        organizationID,
      },
    });
  },
  updateOrganization(organizationID, name, customerNumber, systemUsers, compareLocationFeatures) {
    return httpClient.post('api/update-organization', {
      organizationID,
      name,
      customerNumber,
      systemUsers,
      compareLocationFeatures,
    });
  },
  deleteOrganization(organizationID) {
    return httpClient.post('api/delete-organization', { organizationID });
  },
  getOrganizationToClinicName(clinicName) {
    return httpClient.get('api/get-organization-to-clinic-name', {
      params: {
        clinicName,
      }
    });
  },
};

export default OrganizationsService;
