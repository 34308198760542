import httpClient from '../httpClient/httpClient';

const CycleTypesService = {
  getCycleTypeNames() {
    return httpClient.get('api/get-cycle-type-names');
  },

  getDirCycleTypeIds() {
    return httpClient.get('api/get-dir-cycle-type-ids');
  }
};

export default CycleTypesService;
