<template>
    <div>
      <canvas :id="`licSrvChart-${identifier}`"></canvas>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js';

export default {
  name: 'LicenseServerChart',
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    identifier: {
      type: String,
      default: Math.random().toString(),
    },
  },
  mounted() {
    if (this.chartData) {
      this.drawChart();
    }
  },
  methods: {
    drawChart() {
      if (this.chartData) {
        const ctx = document.getElementById(`licSrvChart-${this.identifier}`);
        // eslint-disable-next-line no-new
        this.chart = new Chart(ctx, this.chartData);
      }
    },
  },
};
</script>
