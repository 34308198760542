import httpClient from '../httpClient/httpClient';

const CycleStatisticsService = {
  getCycleStatistic(clinicID, startDate, endDate) {
    return httpClient.get('api/get-cycle-statistic', {
      params: {
        clinicID,
        startDate,
        endDate,
      },
    });
  },
};

export default CycleStatisticsService;
