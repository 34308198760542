<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Cycle Types - Clinic Statistics" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedClinic"
          :items="clinicNames"
          :readonly="!selectedOrganization"
          color="primaryAccent"
          dense
          hide-details
          label="Clinic"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col align-self="center" class="pr-0" cols="2">
        <v-menu
          ref="startDateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startDate" v-bind="attrs" v-on="on" dense hide-details label="Start date" outlined readonly></v-text-field>
          </template>
          <v-date-picker v-model="startDate" no-title scrollable @change="$refs.startDateMenu.save(startDate) || $store.commit('selectedProperties/setClinicCycleTypesStatisticsStartDate', startDate)">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" class="pl-0" cols="auto">
        <mex-btn icon="mdi-close" iconOnly small @click="startDate = null || $store.commit('selectedProperties/setClinicCycleTypesStatisticsStartDate', startDate)" />
      </v-col>
      <v-col align-self="center" class="pr-0" cols="2">
        <v-menu
          ref="endDateMenu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endDate" v-bind="attrs" v-on="on" dense hide-details label="End date" outlined readonly></v-text-field>
          </template>
          <v-date-picker v-model="endDate" no-title scrollable @change="$refs.endDateMenu.save(endDate) || $store.commit('selectedProperties/setClinicCycleTypesStatisticsEndDate', endDate)">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" class="pl-0" cols="auto">
        <mex-btn icon="mdi-close" iconOnly small @click="endDate = null || $store.commit('selectedProperties/setClinicCycleTypesStatisticsEndDate', null)" />
      </v-col>
      <v-col align-self="center" cols="1">
        <mex-btn icon="mdi-refresh" iconOnly @click="fullReload" />
      </v-col>
    </v-row>
    <v-row v-if="!selectedOrganization && !selectedClinic" justify="center">
      <mex-p content="Select an organization" fontSize="overline" />
    </v-row>
    <v-row v-if="selectedOrganization && !selectedClinic" justify="center">
      <mex-p content="Select a clinic" fontSize="overline" />
    </v-row>
    <mex-sperm-spinner v-if="statisticLoading && selectedOrganization && selectedClinic" spinnerText="Loading statistic data" />
    <template v-if="selectedOrganization && selectedClinic">
      <template v-if="
        (!!locationBarChartData && Object.keys(locationBarChartData).length)
        && (!!locationLineChartData && Object.keys(locationLineChartData).length)"
      >
        <v-row v-for="locationName in Object.keys(locationBarChartData)" :key="locationBarChartData[locationName].locationID" justify="center">
          <v-col cols="12">
            <mex-sheet color="foreground" rounded>
              <v-row justify="start">
                <mex-p :content="`${locationName}`" fontSize="h5" fontWeight="bold-italic" />
              </v-row>
              <v-row justify="center">
                <!-- control center -->
                <v-col cols="6">
                  <v-select
                    v-model="selectedCycleTypes[locationName]"
                    :items="cycleTypes"
                    @change="selectedCycleTypesChanged"
                    item-text="name"
                    return-object
                    label="Select Cycle-Types to inspect"
                    outlined
                    dense
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    color="primaryAccent"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-row justify="center">
                    <mex-btn content="Reset" :icon-only="$vuetify.breakpoint.smAndDown" rounded tooltip="Only show reported Cycle Types" icon="mdi-reload" @click="() => { getPreselectedCycleTypesForLocationName(unprocessedLocationChartData, locationName); selectedCycleTypesChanged(); }"></mex-btn>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row justify="center">
                    <mex-btn content="Clear all" :icon-only="$vuetify.breakpoint.smAndDown" rounded tooltip="Remove all Cycle Types from statistic" icon="mdi-trash-can" @click="() => { selectedCycleTypes[locationName] = []; selectedCycleTypesChanged(); }"></mex-btn>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row justify="center">
                    <mex-btn content="Load DIR types" :icon-only="$vuetify.breakpoint.smAndDown" rounded tooltip="Load all DIR-relevant Cycle Types" icon="mdi-auto-fix" @click="() => { getDirCycleTypesForLocationName(unprocessedLocationChartData, locationName); selectedCycleTypesChanged(); }"></mex-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="selectedCycleTypes[locationName].length">
                <v-col cols="6">
                  <v-row justify="center">
                    <mex-p content="Summary" font-size="h6" font-weight="bold-italic"></mex-p>
                  </v-row>
                  <license-server-chart
                    v-if="!!locationBarChartData[locationName]"
                    :chartData="{
                      type: 'bar',
                      data: locationBarChartData[locationName],
                      options: {
                        legend: {
                          display: false,
                        },
                        responsive: true,
                        scales: {
                          xAxes: [{ stacked: true }],
                          yAxes: [{ stacked: true }]
                        }
                      }
                    }"
                    :identifier="`${locationName}-bar-chart`"
                    :key="locationBarChartDataKey"/>
                </v-col>
                <v-col cols="6">
                  <v-row justify="center">
                    <mex-p content="Timeline" font-size="h6" font-weight="bold-italic"></mex-p>
                  </v-row>
                  <license-server-chart
                    v-if="!!locationLineChartData[locationName]"
                    :chartData="{
                      type: 'line',
                      data: locationLineChartData[locationName],
                      options: {
                        legend: {
                          display: false,
                        },
                        tooltips: {
                          enabled: true,
                          mode: 'nearest',
                          intersect: false,
                          callbacks: {
                            title: (item) => item.label,
                          }
                        }
                      }
                    }"
                    :identifier="`${locationName}-line-chart`"
                    :key="locationLineChartDataKey"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card
                    class="mx-auto my-2"
                    color="primary"
                    dark
                    outlined
                  >
                    <v-card-text class="text-h6">
                      <v-icon left>mdi-sigma</v-icon>
                      Sum of selected Statistics: {{ calculateCycleStatisticSum(locationBarChartData[locationName]) }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="!statisticLoading">
        <v-row justify="center">
          <v-col cols="6">
            <mex-sheet rounded color="foreground">
              <v-row justify="center">
                <v-icon large color="error">mdi-alert-circle</v-icon>
              </v-row>
              <v-row justify="center">
                <mex-p content="No Cycle Statistics Available" fontSize="h5" fontWeight="bold-italic" />
              </v-row>
              <v-row justify="center">
                <mex-p :content="`Unfortunately, there are no cycle statistics reported for the selected clinic during the specified period.`" fontSize="body-1" />
              </v-row>
              <v-row justify="center">
                <mex-btn content="Refresh" rounded icon="mdi-refresh" @click="fullReload" />
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

  <script>
  import { mapGetters } from "vuex";
  import ClinicsService from "../../services/clinics.service";
  import OrganizationsService from "../../services/organizations.service";
  import LicenseServerChart from "../../components/LicSrvComponents/LicenseServerChart.vue";
  import CycleStatisticsService from "../../services/cycleStatistics.service";
  import CycleTypesService from "../../services/cycleTypes.service";
  import requiredPermissions from "../../requiredPermissions";
  import { assignSeveralProperties } from "../../functions/assignPropertyIfExists";
  import MexBtn from "../../components/MedITEX_Vue_Components/MexComponents/MexButton.vue";

  export default {
    name: 'ClinicCycleTypesStatistics',
    components: { MexBtn, LicenseServerChart },
    computed: {
      ...mapGetters('sysAuth', ['getUserPermissions']),
      getSelectedClinic() {
        return this.selectedClinic;
      },
    },
    data() {
      return {
        // visualization
        statisticLoading: false,
        // selection data
        selectedOrganization: null,
        selectedClinic: null,
        organizationNames: [],
        clinicNames: [],
        preSelection: {
          clinic: null,
          organization: null,
          startDate: null,
          endDate: null,
        },
        cycleStatisticsBarCharts: null,
        cycleStatisticsLineCharts: null,
        startDate: this.$dateFormatter.convertJsonDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()).date,
        startDateMenu: false,
        endDate: this.$dateFormatter.convertJsonDate(new Date().toISOString()).date,
        endDateMenu: false,
        cycleTypes: [],
        selectedCycleTypes: {},
        unprocessedLocationChartData: null,
        locationBarChartData: null,
        locationBarChartDataKey: 1,
        locationLineChartData: null,
        locationLineChartDataKey: 1,
        selectedIndices: {},
        hasPermission: false,
        chartBackgroundColors: [
          `rgba(255, 99, 132, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // red
          `rgba(54, 162, 235, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // blue
          `rgba(255, 206, 86, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // yellow
          `rgba(75, 192, 192, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // green
          `rgba(153, 102, 255, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`, // purple
          `rgba(255, 159, 64, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // orange
          `rgba(255, 0, 255, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // magenta
          `rgba(128, 0, 128, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // dark purple
          `rgba(0, 128, 128, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // teal
          `rgba(128, 128, 0, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // olive
          `rgba(0, 255, 127, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // spring green
          `rgba(138, 43, 226, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,  // blue violet
          `rgba(165, 42, 42, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`,   // brown
          `rgba(60, 179, 113, ${this.$vuetify.theme.isDark ? "0.9": "0.7"})`   // medium sea green
        ],
        dirCycleTypes: []
      };
    },
    watch: {
      selectedOrganization: {
        handler() {
          this.fetchClinicNames();
          this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsOrganization', this.selectedOrganization);
          this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsClinic', null);
        },
      },
      selectedClinic: {
        handler() {
          if (this.selectedClinic) {
            this.fetchCycleTypeStatistic();
            this.fetchDirCycleTypes();
            this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsClinic', this.selectedClinic);
          }
        },
      },
    },
    created() {
      this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.cycleTypesStatistics, this.$store)
        .then((hasPermission) => {
          if (hasPermission) {
            this.hasPermission = true;
            this.fetchOrganizationNames();
            assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic'], this.preSelection, this.$store, 'clinicCycleTypesStatistics');
            if (this.$route.params && Object.keys(this.$route.params).length) {
              if (this.$route.params.startDate) {
                this.startDate = this.$route.params.startDate;
                this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsStartDate', this.startDate);
              }
              if (this.$route.params.endDate) {
                this.endDate = this.$route.params.endDate;
                this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsEndDate', this.endDate);
              }
            } else if (this.$route.query && Object.keys(this.$route.query).length) {
              if (this.$route.query.startDate) {
                this.startDate = this.$route.query.startDate;
                this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsStartDate', this.startDate);
              }
              if (this.$route.query.endDate) {
                this.endDate = this.$route.query.endDate;
                this.$store.commit('selectedProperties/setClinicCycleTypesStatisticsEndDate', this.endDate);
              }
            } else {
              if (this.$store.getters["selectedProperties/clinicCycleTypesStatisticsStartDate"]) {
                this.startDate = JSON.parse(this.$store.getters["selectedProperties/clinicCycleTypesStatisticsStartDate"]) || this.$dateFormatter.convertJsonDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()).date;
              }
              if (this.$store.getters["selectedProperties/clinicCycleTypesStatisticsEndDate"]) {
                this.endDate = JSON.parse(this.$store.getters["selectedProperties/clinicCycleTypesStatisticsEndDate"]) || this.$dateFormatter.convertJsonDate(new Date().toISOString()).date;
              }
            }
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        })
    },
    methods: {
      fetchDirCycleTypes() {
        CycleTypesService.getDirCycleTypeIds()
          .then((response) => {
            console.log(response);
            this.dirCycleTypes = response.data.dirCycleTypeIds;
          })
          .catch((err) => {
            this.$toast.error(`Problem while retrieving DIR-Cycle Types: ${err}`);
          });
      },
      calculateCycleStatisticSum(locationCycleStatistics) {
        return locationCycleStatistics.datasets.map(dataset => dataset.data).reduce((cycleStatisticsSum, object) => {
          cycleStatisticsSum += object.reduce((sum, value) => sum + value, 0);
          return cycleStatisticsSum;
        }, 0);
      },
      updateSelectedIndices() {
        this.selectedIndices = {};
        Object.keys(this.selectedCycleTypes).forEach((locationName) => {
          this.selectedIndices[locationName] = this.selectedCycleTypes[locationName].map(selectedItem => this.cycleTypes.indexOf(selectedItem));
        });
      },
      selectedCycleTypesChanged() {
        // bar chart update
        this.updateSelectedIndices();
        this.locationBarChartData = this.createBarChartData(this.unprocessedLocationChartData);
        this.locationBarChartDataKey++;
        // line chart update
        this.locationLineChartData = this.createLineChartData(this.unprocessedLocationChartData);
        this.locationLineChartDataKey++;
      },
      fetchOrganizationNames() {
        if (this.organizationNames.length === 0) {
          OrganizationsService.getOrganizationNames()
            .then((organizationResponse) => {
              organizationResponse.data.forEach((orga) => {
                this.organizationNames.push({
                  value: orga.OrganizationID,
                  text: orga.name,
                });
              });
              if (this.preSelection.organization) {
                this.selectedOrganization = this.preSelection.organization;
                this.preSelection.organization = null;
              } else {
                this.selectedOrganization = null;
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast.error(err);
            });
        }
      },
      fetchClinicNames() {
        this.clinicNames = [];
        if (this.selectedOrganization) {
          ClinicsService.getClinicNames(this.selectedOrganization)
            .then((clinicResponse) => {
              clinicResponse.data.forEach((clinic) => {
                this.clinicNames.push({
                  value: clinic.ClinicID,
                  text: clinic.name,
                });
              });
              if (this.preSelection.clinic) {
                this.selectedClinic = this.preSelection.clinic;
                this.preSelection.clinic = null;
              } else {
                this.selectedClinic = null;
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast.error(err);
            });
        }
      },
      createBarChartData(locationCycleStatistics) {
        let barChartData = {};

        Object.keys(locationCycleStatistics).forEach((locationName) => {
          barChartData[locationName] = {
            locationID: locationCycleStatistics[locationName].locationID,
            labels: this.selectedCycleTypes[locationName].map(cycleType => cycleType.name),
            datasets: locationCycleStatistics[locationName].data.reduce((acc, object) => {
              // check if current cycle type is already in the accumulator
              const found = acc.find((item) => item.cycleTypeID === object.CycleTypeID);
              if (found) {
                found.quantitySum += Number(object.quantity);
              } else if (this.selectedCycleTypes[locationName].find(cycleType => cycleType.CycleTypeID === object.CycleTypeID)) {
                // if not, add new entry for this CycleType
                acc.push({
                  cycleTypeID: object.CycleTypeID,
                  quantitySum: Number(object.quantity)
                });
              }
              return acc;
            }, [])
              // sort data by CycleTypeID
              .sort((a, b) => a.cycleTypeID - b.cycleTypeID)
              // map sorted data to an array of quantity sums for each cycle type, matching for graph.js
              .map((cycleStatistics) => {
                return {
                  label: this.cycleTypes.find(cycleType => cycleType.CycleTypeID === cycleStatistics.cycleTypeID).name,
                  backgroundColor: this.selectedIndices[locationName].map(i => this.chartBackgroundColors[i]),
                  data: Array.from({length: this.selectedCycleTypes[locationName].length}, (_, i) => i === (this.selectedCycleTypes[locationName].findIndex(selectedCycleType => selectedCycleType.CycleTypeID === cycleStatistics.cycleTypeID)) ? cycleStatistics.quantitySum : 0),
                }
              })
          };
        });

        return barChartData;
      },
      createLineChartData(locationCycleStatistics) {
        let lineChartData = {};
        Object.keys(locationCycleStatistics).forEach((locationName) => {
          const retrievalDays = [...new Set(locationCycleStatistics[locationName].data.map(statistic => statistic.retrievalDate))];
          lineChartData[locationName] = {
            locationID: locationCycleStatistics[locationName].locationID,
            labels: retrievalDays,
            datasets: locationCycleStatistics[locationName].data.reduce((acc, object) => {
              // check if current date is already in the accumulator
              const found = acc.find((item) => item.cycleTypeID === object.CycleTypeID);
              const retrievalDateIndex = retrievalDays.findIndex(date => date === object.retrievalDate);
              if (retrievalDateIndex >= 0) {
                if (found) {
                  found.quantitySum[retrievalDateIndex] += Number(object.quantity);
                } else if (this.selectedCycleTypes[locationName].find(cycleType => cycleType.CycleTypeID === object.CycleTypeID)) {
                  // if not, add new entry for this CycleType
                  acc.push({
                    cycleTypeID: object.CycleTypeID,
                    quantitySum: Array.from({ length: retrievalDays.length }, (_, i) => (i === retrievalDateIndex ? Number(object.quantity) : 0)),
                  });
                }
              } else {
                this.$toast.warning(`An issue occurred in the calculation of the statistics. Caution, data might be falsy for location ${locationName}.`);
              }
              return acc;
            }, [])
              // map sorted data to an array of quantity sums for each cycle type, matching for graph.js
              .map((cycleStatistics) => {
                return {
                  label: this.cycleTypes.find(cycleType => cycleType.CycleTypeID === cycleStatistics.cycleTypeID).name,
                  backgroundColor: this.chartBackgroundColors[cycleStatistics.cycleTypeID - 1],
                  borderColor: this.chartBackgroundColors[cycleStatistics.cycleTypeID - 1],
                  data: cycleStatistics.quantitySum,
                  fill: false,
                }
              })
          };
        });

        return lineChartData;
      },
      getPreselectedCycleTypesForLocationName(locationCycleStatistics, locationName) {
        this.selectedCycleTypes[locationName] = this.cycleTypes.filter(cycleType => locationCycleStatistics[locationName].data
          .filter(dataPoint => Number(dataPoint.quantity) > 0)
          .reduce((acc, object) => {
            if (!acc.includes(object.CycleTypeID)) {
              acc.push(object.CycleTypeID);
            }
            return acc;
          }, []).includes(cycleType.CycleTypeID));
      },
      getDirCycleTypesForLocationName(locationCycleStatistics, locationName) {
        this.selectedCycleTypes[locationName] = this.cycleTypes.filter(cycleType => locationCycleStatistics[locationName].data
          .filter(dataPoint => Number(dataPoint.quantity) > 0)
          .reduce((acc, object) => {
            if (!acc.includes(object.CycleTypeID) && this.dirCycleTypes.includes(object.CycleTypeID)) {
              acc.push(object.CycleTypeID);
            }
            return acc;
          }, []).includes(cycleType.CycleTypeID));
      },
      getPreselectedCycleTypes(locationCycleStatistics) {
        this.selectedCycleTypes = {};
        // choose cycle types as preselection which have reported values > 0 in their statistic
        Object.keys(locationCycleStatistics).forEach((locationName) => {
          this.getPreselectedCycleTypesForLocationName(locationCycleStatistics, locationName)
        });
      },
      fetchCycleTypeStatistic() {
        this.statisticLoading = true;
        CycleStatisticsService.getCycleStatistic(this.selectedClinic, this.startDate, this.endDate)
          .then((response) => {
            this.cycleTypes = response.data.cycleTypes;
            this.unprocessedLocationChartData = response.data.cycleStatistics;
            this.getPreselectedCycleTypes(this.unprocessedLocationChartData);
            this.updateSelectedIndices();
            // preselect cycle types which have any values in it
            ['Bar', 'Line'].forEach((chartType) => {
              this[`location${chartType}ChartData`] = this[`create${chartType}ChartData`](response.data.cycleStatistics);
            });
            this.statisticLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(err);
          });
      },
      getLineHeader(data) {
        return `${data.locationName} - Cycle Types from ${data.data.labels[0]} - ${data.data.labels[data.data.labels.length - 1]}`;
      },
      getPieHeader(data) {
        return `${data.locationName} - Summary`;
    },
    fullReload() {
      this.$router.push({
        name: 'ParamsReload',
        params: {
          name: 'ClinicCycleTypesStatistics',
          reloadParams: {
            organizationID: this.selectedOrganization,
            clinicID: this.selectedClinic,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        },
      });
    },
  },
};
</script>

<style>
.ct-grids line {
  stroke: whitesmoke !important;
}

.ct-labels span {
  color: steelblue;
}
</style>
