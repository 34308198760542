/**
 * checks a given array of objects if at least one object contains a given property and returns it if found, otherwise an empty string.
 *
 * @param objectArrayToCheck - array of objects which may contain the property
 * @param key - key of property we search for
 * @param store - if defined: store where we write and search for the found properties, if not defined: nothing written/requested in/from store
 * @param storeGetterRoute - only necessary if store is defined: getter-route, if not defined: property won't be searched for in store
 * @param storeSetterRoute - only necessary if store is defined: setter-route, if not defined: property won't be written in store
 *
 * @return {any|string} - if found: value, otherwise: empty string
 */
const assignPropertyIfExists = (objectArrayToCheck, key, store = null, storeGetterRoute = "", storeSetterRoute = "") => {
  if (store && storeGetterRoute !== "") {
    objectArrayToCheck.push({ [key]: store.getters['selectedProperties/' + storeGetterRoute] });
  }
  const assignee = objectArrayToCheck.some(object => foundProperty = object[key]) ? JSON.parse(foundProperty) : null;
  if (store && (storeSetterRoute !== "") && assignee) {
    store.commit('selectedProperties/' + storeSetterRoute, assignee);
  }
  return assignee || "";
};

/**
 * checks a given array of objects for a given array of properties and assigns them, if found, to an object.
 *
 * @param objectArrayToCheck - array of objects which may contain the properties
 * @param keyArray - array of keys of properties we search for - !!! ATTENTION !!! SO FAR THE ACTUAL KEYS WHERE WE SEARCH FOR ARE KEYARRAY-PROPERTY CONCATENATED WITH "ID".
 * TODO JoRe
 * Turn keyArray-values to objects with properties "keyValue" and "storeRouteSuffix" so that also keys without ID at the end can be searched with this function!
 * @param destinationObject - object where the properties are - if found - assigned to
 * @param store - if defined: store where we write and search for the found properties, if not defined: nothing written/requested in/from store
 * @param storePrefix - the getter-route AKA the name of properties in store/selectedProperties.store.js in lowerCamelCase
 */
const assignSeveralProperties = (objectArrayToCheck, keyArray, destinationObject, store = null, storePrefix = "") => {
  const storePrefixInUpperCase = storePrefix.charAt(0).toUpperCase() + storePrefix.slice(1);
  keyArray.forEach((preselectedProperty) => {
    const propertyInUpperCase = preselectedProperty.charAt(0).toUpperCase() + preselectedProperty.slice(1);
    destinationObject[preselectedProperty] = assignPropertyIfExists(objectArrayToCheck, preselectedProperty + 'ID', store, storePrefix + propertyInUpperCase, 'set' + storePrefixInUpperCase + propertyInUpperCase, );
  });
};

module.exports = {
  assignPropertyIfExists,
  assignSeveralProperties,
};
