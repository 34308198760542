import httpClient from "../httpClient/httpClient";

const ClinicsService = {
  getClinicsOverview(page, size, filterData) {
    return httpClient.get("api/get-clinics-data", {
      params: {
        page,
        size,
        filterData
      }
    });
  },
  getClinicNames(
    organizationID = null,
    minimumVersion = null,
    getNotUseBroadcaster = false,
  ) {
    return httpClient.get("api/get-clinic-names", {
      params: {
        organizationID,
        minimumVersion,
        getNotUseBroadcaster,
      }
    });
  },
  getCompressedClinicOverview(getManagedManually) {
    return httpClient.get("api/get-compressed-clinic-overview", {
      params: {
        getManagedManually
      }
    });
  },
  getClinicData(clinicID) {
    return httpClient.get("api/get-clinic-data", {
      params: {
        clinicID
      }
    });
  },
  updateClinicData(clinicID, clinicName, clinicShortname, organizationID, registerTypeID, customerNumber, blocklisted, sqlStatementIDs, macAddress, notUseBroadcaster, managedManually, checkForMeditexBackups, lastContactChanged) {
    return httpClient.post("api/update-clinic-data", {
      clinicID,
      clinicName,
      clinicShortname,
      organizationID,
      registerTypeID,
      customerNumber,
      blocklisted,
      sqlStatementIDs,
      macAddress,
      notUseBroadcaster,
      managedManually,
      checkForMeditexBackups,
      lastContactChanged
    });
  },
  updateForcedDeactivationDate(clinicID, forcedDeactivationDate) {
    return httpClient.post("api/update-forced-deactivation-date", {
      clinicID,
      forcedDeactivationDate
    });
  },
  setNewMacAddress(clinicUUID, macAddress) {
    return httpClient.post("api/set-new-mac-address", {
      clinicUUID,
      macAddress
    });
  },
  getClinicsToOrganizationName(organizationName) {
    return httpClient.get('api/get-clinics-to-organization-name', {
      params: {
        organizationName,
      }
    });
  },
  deleteClinicData(clinicID) {
    return httpClient.post("api/delete-clinic-data", {
      clinicID,
    });
  },
  getAffectedRowsOfDeleteClinic(clinicID) {
    return httpClient.get("api/get-affected-rows-of-delete-clinic", {
      params: {
        clinicID
      }
    });
  },
};

export default ClinicsService;
